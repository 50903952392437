<template>
    <section>
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <b-col cols="8">
                    <b-card class="invoice-actions" no-body>
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="3">
                                    <label for="date_start">Fecha Inicio</label>
                                    <flat-pickr id="date_start" v-model="filter.start"
                                        class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                        placeholder="DD/MM/YYYY" />
                                </b-col>
                                <b-col cols="12" md="3">
                                    <label for="date_start">Fecha Fin</label>
                                    <flat-pickr id="date_start" v-model="filter.end"
                                        class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                        placeholder="DD/MM/YYYY" />
                                </b-col>

                                <b-col cols="12" md="6">
                                    <div class="demo-inline-spacing">
                                        <b-button size="sm" @click="getTransfers" variant="primary"
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                                            <feather-icon icon="SearchIcon" />
                                            Buscar
                                        </b-button>

                                        <b-button size="sm" v-if="filterTransfersList.length > 0" @click="downloadReport" variant="secondary" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                                            <feather-icon icon="DownloadCloudIcon" />
                                            Exportar
                                        </b-button>
                                        
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>

            <b-card no-body class="mb-0 mt-1">
                <b-tabs v-model="tabActive" fill content-class="pt-1">
                    <b-tab @click="tabActive = 0" title="Traspasos en Automático">
                        <div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                        :class="`${filterTransfersList.length > 10 ? 'b-table-sticky-header' : ''}`">
                            <table ref="articlesTable" class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                                <thead>
                                    <tr>
                                        <th style="font-size: 10px!important;">Folio Docto</th>
                                        <th style="font-size: 10px!important;">Folio Traspaso</th>
                                        <th style="font-size: 10px!important;">Almacen Venta</th>
                                        <th style="font-size: 10px!important;">Almacen Surte</th>
                                        <th style="font-size: 10px!important;">Articulo Id</th>
                                        <th style="font-size: 10px!important;">Articulo</th>
                                        <th style="font-size: 10px!important;">Clave Principal</th>
                                        <th style="font-size: 10px!important;">Unidad Medida</th>
                                        <th style="font-size: 10px!important;">Cantidad</th>
                                        <th style="font-size: 10px!important;">Fecha</th>
                                        <th style="font-size: 10px!important;">Creado Por</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in filterTransfersList" :key="index">
                                        <td style="font-size: 10px!important;">{{ item.folio }}</td>
                                        <td style="font-size: 10px!important;">{{ item.docto_In_Folio }}</td>
                                        <td style="font-size: 10px!important;">{{ item.almacen_Venta }}</td>
                                        <td style="font-size: 10px!important;">{{ item.almacen_Surte }}</td>
                                        <td style="font-size: 10px!important;">{{ item.articulo_Id }}</td>
                                        <td style="font-size: 10px!important;">{{ item.articulo }}</td>
                                        <td style="font-size: 10px!important;">{{ item.articulo_Clave }}</td>
                                        <td style="font-size: 10px!important;">{{ item.unidad_Medida }}</td>
                                        <td style="font-size: 10px!important;">{{ item.unidades }}</td>
                                        <td style="font-size: 10px!important;" class="text-capitalize">{{ formatDate(item.fecha_Alta) }}</td>
                                        <td style="font-size: 10px!important;">{{ item.usuario_Nombre }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-tab>

                    <b-tab @click="tabActive = 1" title="Bitacora">
                        <div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                        :class="`${filterTransfersList.length > 10 ? 'b-table-sticky-header' : ''}`">
                            <table ref="articlesTable" class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                                <thead>
                                    <tr>
                                        <th style="font-size: 10px!important;">Folio Docto</th>
                                        <!-- <th style="font-size: 10px!important;">Folio Traspao</th> -->
                                        <th style="font-size: 10px!important;">Almacen Venta</th>
                                        <th style="font-size: 10px!important;">Almacen Surte</th>
                                        <th style="font-size: 10px!important;">Articulo Id</th>
                                        <th style="font-size: 10px!important;">Articulo</th>
                                        <th style="font-size: 10px!important;">Clave Principal</th>
                                        <th style="font-size: 10px!important;">Unidad Medida</th>
                                        <th style="font-size: 10px!important;">Cantidad</th>
                                        <th style="font-size: 10px!important;">Fecha</th>
                                        <th style="font-size: 10px!important;">Creado Por</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in filterTransfersList" :key="index">
                                        <td style="font-size: 10px!important;">{{ item.folio }}</td>
                                        <!-- <td style="font-size: 10px!important;">{{ item.docto_In_Folio }}</td> -->
                                        <td style="font-size: 10px!important;">{{ item.almacen_Venta }}</td>
                                        <td style="font-size: 10px!important;">{{ item.almacen_Surte }}</td>
                                        <td style="font-size: 10px!important;">{{ item.articulo_Id }}</td>
                                        <td style="font-size: 10px!important;">{{ item.articulo }}</td>
                                        <td style="font-size: 10px!important;">{{ item.articulo_Clave }}</td>
                                        <td style="font-size: 10px!important;">{{ item.unidad_Medida }}</td>
                                        <td style="font-size: 10px!important;">{{ item.unidades }}</td>
                                        <td style="font-size: 10px!important;" class="text-capitalize">{{ formatDate(item.fecha_Alta) }}</td>
                                        <td style="font-size: 10px!important;">{{ item.usuario_Nombre }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-tab>

                    <!-- New Tab Input (Using tabs-end slot) -->
                    <template #tabs-end>
                        <b-nav-item role="presentation">
                            <b-input-group class="d-flex align-items-center justify-content-end w-100">
                                <b-form-input ref="inputSearchItems" autofocus v-model="searchItemTemp" size="sm" 
                                @keydown.enter="applySearch"
                                class="d-inline-block" block :placeholder="tabActive == 1 ? `Buscar por clave o nombre articulo` : `Buscar por clave, articulo, folio traspaso`"/>
                                <b-input-group-prepend>
                                    <b-button @click="applySearch" size="sm" variant="primary">
                                        <feather-icon icon="SearchIcon" />
                                    </b-button>

                                    <b-button v-if="searchItemTemp" @click="clearSearch" size="sm" variant="danger">
                                        <feather-icon icon="XIcon" />
                                    </b-button>
                                </b-input-group-prepend>                                
                            </b-input-group>
                        </b-nav-item>
                    </template>
                </b-tabs>
            </b-card>
        </b-overlay>
    </section>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BTable,
    BTableLite,
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BNavItem,

} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import moment from 'moment';
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import * as helper from '@/libs/helpers';

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes';
const DeliveryRoutesResource = new DeliveryRoutesProvider();



export default {
    components: {
        BCard,
        BCardBody,
        BCardHeader,
        BCardText,
        BTable,
        BTableLite,
        BOverlay,
        BSpinner,
        BRow,
        BCol,
        BButton,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BTabs,
        BTab,
        BNavItem,

        //
        flatPickr,
        vSelect,
    },
    directives: {
        Ripple,
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        handlePdfProgress(evt) {
            this.loading = true
            if (evt >= 100) {
                this.loading = false
            }
        },
    },
    setup() {

        const loading = ref(false)
        const prices = ref([])
        const search = ref(null)
        const transfers = ref([])
        const filterTransfersList = ref([]);
        const tabActive = ref(0)
        const searchItemTemp = ref(null)

        const filter = ref({
            start: moment().startOf('month').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
        })


        const getTransfers = async () => {

            transfers.value = []
            filterTransfersList.value = []
            const query = {
                inicio: filter.value.start, // null, //'2024-02-19',
                fin: filter.value.end, // null, //'2024-03-25',
                es_bitacora: tabActive.value,
            }

            try {
                loading.value = true
                const { data } = await DeliveryRoutesResource.getTransfersWarehouses(query)
                loading.value = false
                if (data.isSuccesful) {
                    transfers.value = data.data 
                    filterTransfersList.value = [...transfers.value]
                } else {
                    helper.danger(data.message)
                }
            }catch(e) {
                loading.value = false
                helper.handleResponseErrors(e)
            }            
        }


        const downloadReport = async () => {
            loading.value = true
            const query = {
                inicio: filter.value.start, 
                fin: filter.value.end,
                es_bitacora: tabActive.value
            }
            DeliveryRoutesResource.exportTransferWarehouses(query).then(response => {
                loading.value = false
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const title = tabActive.value == 1 ? 'Traspaso-Bitacora' : 'Traspaso-Automatico'
                link.setAttribute('download', `${title}-${moment.now()}.xlsx`);
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
                loading.value = false
            })
        }

        const clearSearch = () => {
            searchItemTemp.value = '';
            search.value = '';

            loading.value = true
            setTimeout(() => {                
                applySearch();                    
            }, 100);
            loading.value = false
        }

        
        const applySearch = () => {
            const searchTerm = searchItemTemp.value.toLowerCase();
            search.value = searchItemTemp.value;

            if (!searchTerm) {
                // Si no hay término de búsqueda, muestra todos los datos
                filterTransfersList.value = [...transfers.value];
            } else {
                // Aplica el filtro
                filterTransfersList.value = transfers.value.filter(item =>
                    item.docto_In_Folio?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.articulo_Clave?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.articulo.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }
        };

        const printReport = () => {
            window.print()
        }

        onMounted(async () => {
            // await getTransfers()
        })

        watch(tabActive, async (value) => {
            // await getTransfers()
        })

        return {
            search,
            loading,
            transfers,
            filter,
            tabActive,
            searchItemTemp,
            filterTransfersList,

            //Methods   
            getTransfers,         
            printReport,
            downloadReport,
            applySearch,
            clearSearch,
        };
    }
}
</script>

<style lang="scss">
//@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    max-height: calc(100vh - 200px)!important;
}
</style>